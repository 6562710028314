<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <CasesHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p>
          我姓叶，今年43岁，是一名全职太太。我的儿子9岁，在国内就读国际小学。因为儿时学业压力过大给我留下的阴影让我决定我的小孩不论成绩如何，将来都要接受更加自由和开放的教育，能够为了自己的兴趣而学习，而不只是每日伏案死读书。所以在我儿子刚出生没多久，我就决定让他就读体制外的课程，方便将来出国留学。 </p>
        <p>孩子还小的时候我们选择在国内上学，既方便照顾家里的生意，也不耽误亲子时光。但同时，我也在着手移民这件事情。 </p>
        <p>在参加了多家移民中介公司的讲座之后，经过多方面的比较，我和家人选择了跃迁。刚接触就对跃迁的专业非常满意，
          他们的顾问非常专业，能够对于我提出的问题给以很好的回答。并且能够为我们移民后的生活给出适当的建议和帮助。
          虽然我曾经去美国旅游过几次，但毕竟在当地没有相熟的朋友，很多生活、择校、选择住房等实际问题依然让我非常头疼。跃迁都在这些方面给了我非常实际的帮助。 </p>
        <p>在指定移民方案的时候，跃迁为我选择了父亲赠与的方式，父亲通过将名下一套房产抵押给银行获得了借款，并将这笔资金赠与给我办理移民。
          整个过程中，跃迁给予了非常细致和耐心的指导，帮我们联系了多家银行，也帮助我们收集了符合美国移民局要求的资金来源证明文件。我也非常顺利地拿到了绿卡。
          我已经准备下个学期带我儿子开始这漫漫海外生活之路，十分感谢跃迁的帮助！
        </p>
      </div>
      <p>
        <img loading="lazy" class="aligncenter" src="@/assets/Cases/2/1.jpg">
      </p>
      <CasesFooter class="mt-5" :index="info.index"/>
    </div>
  </div>

</template>

<script>
import CasesHeader from "@/components/Cases/CasesHeader";
import CasesFooter from "@/components/Cases/CasesFooter";

export default {
  name: "Cases2",
  components: {CasesFooter, CasesHeader},
  data() {
    return {
      info: this.$route.meta,
    }
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>